import React, { useEffect ,useState} from 'react'
import Aos from "aos"
import "aos/dist/aos.css"
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import tEn from './locals/en/translation.json';
import tAr from './locals/ar/translation.json';
import tKu from './locals/fa/translation.json';
import {getDocs,collection} from "firebase/firestore"
import{db} from "../firebase";
i18n
  .use(initReactI18next) 
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: tEn
      },
      ar: {
        translation: tAr
      },
      fa: {
        translation: tKu
    },

    lng: "en", 
    fallbackLng: "en",
    interpolation: {
      escapeValue: false 
    }}});


const Vvv = () => {


  const [servicelist,setServiceList]=useState([]);
  const serviceCllectionRef=collection(db,"miss-viss");
  const getServiceList =async()=>{
    //read data 
   
    const data=await getDocs(serviceCllectionRef);
    const filteredData=data.docs.map((doc)=>({...doc.data(),id:doc.id,}))
    console.log(filteredData)
    setServiceList(filteredData)
        }
  useEffect(()=>{

    getServiceList();
  },[])

  const lang =(localStorage.getItem(localStorage.key("i18nextLng")));

    const { t ,i18n} = useTranslation();
	document.body.dir = i18n.dir();
    useEffect(()=>{
        Aos.init({duration: 300})
      },[])
  return (
    <div  className='partt'>
        <div  className="partWrapper">

            <div className="partt1">

            <div  className="part11Wrapper">
                
                
                <div className="leftPartt" >
              
                    
                {
                 servicelist.map((elem) => {
                  const { id, name, image, description } = elem;

                  return (
                 <>
                <img src="https://cdn-icons-png.flaticon.com/512/4185/4185501.png"></img>
        
            
               
                {

lang==="fa" ?
<h1>{elem.kumissionTitle}</h1> 
   : lang==="ar"?
   <h1>{elem.armissionTitle}</h1> 
    : <h1>{elem.enmissionTitle}</h1> 

} 
 {

lang==="fa" ?
<p className="p service__text p__color">{elem.kuMission }</p>
   : lang==="ar"?
   <p className="p service__text p__color">{elem.arMission}</p>
    :<p className="p service__text p__color">{elem.enMission1}</p>

}
{

lang==="fa" ?
<p className="p service__text p__color"></p>
   : lang==="ar"?
   <p className="p service__text p__color"></p>
    :<p className="p service__text p__color">{elem.enMission2}</p>

}

                </>
                  )})}
              
                    </div>



	
</div>





		</div>
		
	</div>

                    
                  
 

          


           
              <div className="partWrapper">

<div className="partt1">

<div className="part1Wrapper">
    
   
    <div className="leftPart" >
      
       
    {
                 servicelist.map((elem) => {
                  const { id, name, image, description } = elem;

                  return (
                 <>

<img src="https://cdn-icons-png.flaticon.com/512/2421/2421304.png"></img>
        
            
               
                {

lang==="fa" ?
<h1>{elem.kuvissionTitle}</h1> 
   : lang==="ar"?
   <h1>{elem.arvissionTitle}</h1> 
    : <h1>{elem.envissionTitle}</h1> 

} 
 {

lang==="fa" ?
<p className="p service__text p__color">{elem.kuVission1 }</p>
   : lang==="ar"?
   <p className="p service__text p__color">{elem.arVission1 }</p>
    :<p className="p service__text p__color">{elem.enVission1}</p>

}
{

lang==="fa" ?
<p className="p service__text p__color">{elem.kuVission2 }</p>
   : lang==="ar"?
   <p className="p service__text p__color">{elem.arVission2 }</p>
    :<p className="p service__text p__color">{elem.enVission2}</p>

}
{

lang==="fa" ?
<p className="p service__text p__color">{elem.kuVission3 }</p>
   : lang==="ar"?
   <p className="p service__text p__color">{elem.arVission3 }</p>
    :<p className="p service__text p__color">{elem.enVission3}</p>

}

{

lang==="fa" ?
<p className="p service__text p__color">{elem.kuVission4 }</p>
   : lang==="ar"?
   <p className="p service__text p__color">{elem.arVission4 }</p>
    :<p className="p service__text p__color">{elem.enVission4}</p>

}
{

lang==="fa" ?
<p className="p service__text p__color">{elem.kuVission5 }</p>
   : lang==="ar"?
   <p className="p service__text p__color">{elem.arVission5 }</p>
    :<p className="p service__text p__color">{elem.enVission5}</p>

}




                </>
                  )})}

        </div>
     
    </div>

</div>

</div>

            </div>
   
  )
}

export default Vvv