
export const MenuItemss = [
  {
    image:  "./assets/img/us.webp",
    cName: 'dropdown-link',
    lname:'en',
  },
  
  {
    image:"./assets/img/kkk.webp",
    cName: 'dropdown-link',
    lname:'ar',
  },
  {
    image: "./assets/img/ku.png",
    cName: 'dropdown-link',
    lname:'fa',
  }
];