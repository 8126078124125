
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import {getFirestore} from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyApeg6DIboblGCNT6vrNBQztPYDeVE56GI",
    authDomain: "alfakahama-440fd.firebaseapp.com",
    projectId: "alfakahama-440fd",
    storageBucket: "alfakahama-440fd.appspot.com",
    messagingSenderId: "881039280835",
    appId: "1:881039280835:web:355d1671aa4e2f22233076",
    measurementId: "G-E0XC19JM1T"
  };

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const storage=getStorage(app);
export const db=getFirestore(app);