
import { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import 'animate.css';
import Aos from "aos";
import "aos/dist/aos.css";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import tEn from './locals/en/translation.json';
import tAr from './locals/ar/translation.json';
import tKu from './locals/fa/translation.json';

import {getDocs,collection} from "firebase/firestore"
import{db} from "../firebase";
i18n
  .use(initReactI18next) 
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: tEn
     
      },
      ar: {
        translation: tAr
      },
      fa: {
        translation: tKu
    },

    lng: "en", 
    fallbackLng: "en",
    interpolation: {
      escapeValue: false 
    }}});
export const Projects = () => {

  const [projectList,setProjectList]=useState([]);
  const projectCllectionRef=collection(db,"projects");
  const getProjectList =async()=>{
    //read data 
   
    const data=await getDocs(projectCllectionRef);
    const filteredData=data.docs.map((doc)=>({...doc.data(),id:doc.id,}))
    console.log(filteredData)
    setProjectList(filteredData)
        }
  useEffect(()=>{

    getProjectList();
  },[])
 
  const lang =(localStorage.getItem(localStorage.key("i18nextLng")));
 const navigate=useNavigate();
 const openp=(id)=>{
  navigate('/',{
    state:{
      id:1
    }
  });
 };
 
  const { t ,i18n} = useTranslation();
	document.body.dir = i18n.dir();

  



  useEffect(()=>{
		Aos.init({duration:2000})
		
	  },[]);

  return (
    <div  className="ppbody">
    
    <Link to='/projects'>   <h1  className="pdetail">{t('Projects')}</h1> </Link>
                            <div className="pcontainer">     
  
      <div  className="prow">
     
   
      {
                 projectList.map((project) => {
                  if(project.id!=0){
             const { id, name, image, description } = project;
          
                           return (
        <div className="pimage">
       
        <img src={image} alt={name} className="img-fluid"/>
          <div className="pdetails">

    
          {

lang==="fa" ?
     <h2 >{project.kuTitle}</h2>
   : lang==="ar"?
    <h2 >{project.arTitle}</h2>
    :  <h2 >{project.enTitle}</h2>
}
        
                                                             
          <div className="pmore"> <Link to='/projects'state={(project.id)}>
            <div className="pread-more" onClick={openp} >
            
            {

lang==="fa" ?
 <>هەمووی ببینە</>
: lang==="ar"?
   <>مشاهدة الكل</>
    :  <>View All</>
}
   
             
              </div>
              </Link>
             </div>

          </div>
        </div>
        )
      }})
                      }
                   
      </div>
    
                                      
      </div> 
                          
    
    </div>
  )
}
export default Projects;