import React,{useState} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Aboutus from './components/Aboutus';
import Header from './components/Header';
import Services from './components/Services';
import Home from './components/pages/Home';
import Foter from './components/Foter';
import Contact from './components/Contact';
import Scrolltotop from './components/Scrolltotop';
import Servicee from './components/Servicee';
import ScrollToTop from "react-scroll-to-top";
import { Media } from './components/Media';
import Test from './components/Test';



function App() {
const [Loading,setLoading]=useState(true);
const spinner=document.getElementById('spinner');

if (spinner){
  setTimeout(()=>{
    spinner.style.display="none";
    setLoading(false);
  },2000)
}

  return (
    <Router>
        
      <Scrolltotop/>
      <ScrollToTop smooth style={{background:'#6A3C99',borderRadius:'25px'}} />
      <Header/>
      <Routes>
        <Route exact path='/about'  element={<Aboutus/>} />
        <Route exact path='/contact'  element={<Contact/>} />
        <Route exact path='/project/:id'  element={ <Media/>} />
        <Route exact path='gallery'  element={ <Test/>} />
        <Route exact path='/services'  element={<Servicee/>} />
        <Route exact path='/projects'  element={<Services/>} />
        <Route exact path='/'  element={<Home/>} />
      </Routes>
      <Foter/>
    </Router>
  );
}
export default App;