import React,{useEffect,useState} from 'react'
import {AiFillFacebook} from "react-icons/ai"
import {AiFillInstagram} from "react-icons/ai"
import {GoLocation} from "react-icons/go"
import {BsPhone} from "react-icons/bs"
import {AiOutlineMail} from "react-icons/ai"
import Aos from "aos";
import "aos/dist/aos.css";
import {AiFillTwitterSquare} from "react-icons/ai"
import { Link } from 'react-router-dom';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import tEn from './locals/en/translation.json';
import tAr from './locals/ar/translation.json';
import tKu from './locals/fa/translation.json';
import {getDocs,collection} from "firebase/firestore"
import{db} from "../firebase";
i18n
  .use(initReactI18next) 
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: tEn
      },
      ar: {
        translation: tAr
      },
      fa: {
        translation: tKu
    },

    lng: "en", 
    fallbackLng: "en",
    interpolation: {
      escapeValue: false 
    }}});


const Foter = () => {
  const lang =(localStorage.getItem(localStorage.key("i18nextLng")));
    const { t ,i18n} = useTranslation();
	document.body.dir = i18n.dir();

  useEffect(()=>{
		Aos.init({duration:1000})
		
	  },[]);
    
  const [servicelist,setServiceList]=useState([]);
  const serviceCllectionRef=collection(db,"footer");
  const getServiceList =async()=>{
    //read data 
   
    const data=await getDocs(serviceCllectionRef);
    const filteredData=data.docs.map((doc)=>({...doc.data(),id:doc.id,}))
    console.log(filteredData)
    setServiceList(filteredData)
        }
  useEffect(()=>{

    getServiceList();
  },[])

  return (
 <>
 
 <section className="foter">

<div className="waves">
  <div className="wave" id='wav1'></div>
  <div className="wave" id='wave2'></div>
  <div className="wave" id='wave3'></div>
  <div className="wave" id='wave4'></div>
 </div>
 <div  className="footer-distributed">
 
<div  className="footer-left">
    <h2>{t('Al Fakhama')}<span>{t('Architects')}</span></h2>
    <h5>{t('Projects ')}<span>{t('Of Luxury')}</span></h5>
    <p className="footer-company-about">
       
        <strong>{t('Al Fakhama Architects ')}</strong>{t('an international engineering consultant office in Architectural and Interior design services in iraq ')}</p>
   
  
</div>

<div   className="footer-center">
{
                 servicelist.map((elem) => {
                  const { id, name, image, description } = elem;

                  return (
                    <>
    <div>
    <a><i ><GoLocation/></i></a>
       
        {

lang==="fa" ?
<p>{elem.kuAddress}</p> 
   : lang==="ar"?
   <p>{elem.arAddress}</p> 
    : <p>{elem.enAddress}</p> 

} 
    </div>

    <div>
    <a ><i ><BsPhone/></i></a>
        
        <p>
          {/* <a href="tel:+964 772 100 0090">{t('+964 772 100 0090')}</a> */}
        {

lang==="fa" ?
<a href={elem.enphoneNo1}>{elem.kuphoneNo1}</a> 
   : lang==="ar"?
   <a href={elem.enphoneNo1}>{elem.arphoneNo1}</a> 
    : <a href={elem.enphoneNo1}>{elem.enphoneNo1}</a> 

} 
        
         <br></br>
         
         {/* <a href="tel:+964 782 100 0090">{t('+964 782 100 0090')}</a> */}
         {

lang==="fa" ?
<a href="tel:+964 782 100 0090">{elem.kuphoneNo2}</a> 
   : lang==="ar"?
   <a href="tel:+964 782 100 0090">{elem.arphoneNo2}</a> 
    : <a href="tel:+964 782 100 0090">{elem.enphoneNo2}</a> 

} 
         </p>
    </div>
    <div>
     <a href="#"><i >< AiOutlineMail /></i></a>
        <p>
         <a href="mailTo:info@alfakhamaco.com">{elem.Email}</a>
          
  
          </p>
    </div>

    <div className="footer-icons">
        <a href="#"><i ><AiFillFacebook/></i></a>
        <a href="#"><i ><AiFillInstagram/></i></a>
        <a href="#"><i ><AiFillTwitterSquare/></i></a>
      
    </div>
      </>
      )})}
</div>
<div  className="footer-right">
   

<p className="footer-links">
        
<Link to='/'>{t('Home')}</Link>
<Link to='/gallery'>{t('Gallery')}</Link>
<Link to='/contact'>{t('Contact')}</Link>


</p>

<p className="footer-company-name">{t('Copyright ©2023 ')}<a target="_blank" href='https://tech-eye.com'><strong>{t('TechEye')}</strong></a>{t(' All rights reserved')}</p>





</div>
</div>

 
 
</section>
 
 </>
  )
}

export default Foter
