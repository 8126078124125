import React, { useState } from 'react';
import { MenuItemss } from '../js/MenuItems';
import { Link } from 'react-router-dom';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import tEn from './locals/en/translation.json';
import tAr from './locals/ar/translation.json';
import tKu from './locals/fa/translation.json';


i18n
  .use(initReactI18next) 
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: tEn
      },
      ar: {
        translation: tAr
      },
      fa: {
        translation: tKu
    },

    lng: "en", 
    fallbackLng: "en",
    interpolation: {
      escapeValue: false 
    }}});

    const changeLang=(l)=>{
      return()=>{
     i18n.changeLanguage(l);
     }}
     

function DropdownL() {
  const { t ,i18n} = useTranslation();
  document.body.dir = i18n.dir();
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  return (
    <>
      <ul
        onClick={handleClick}
        className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}
      >
        {MenuItemss.map((item, index) => {
          return (
            <li key={index}>
              <Link
                className={item.cName}
               
                onClick={changeLang(item.lname)}
                // onClickk={closeMobileMenu}
                // onclick="changeLang(item.lname);closeMobileMenu()"
              >
               
           <img  className='flagimg' src={item.image}></img>
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default DropdownL;
