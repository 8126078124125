
import { useEffect,useState } from "react";
import { Link } from 'react-router-dom';
import 'animate.css';
import Aos from "aos";
import "aos/dist/aos.css";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import tEn from './locals/en/translation.json';
import tAr from './locals/ar/translation.json';
import tKu from './locals/fa/translation.json';
import {getDocs,collection} from "firebase/firestore"
import{db} from "../firebase";
i18n
  .use(initReactI18next) 
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: tEn
      },
      ar: {
        translation: tAr
      },
      fa: {
        translation: tKu
    },

    lng: "en", 
    fallbackLng: "en",
    interpolation: {
      escapeValue: false 
    }}});
export const Service = () => {


  const [servicelist,setServiceList]=useState([]);
  const serviceCllectionRef=collection(db,"services");
  const getServiceList =async()=>{
    //read data 
   
    const data=await getDocs(serviceCllectionRef);
    const filteredData=data.docs.map((doc)=>({...doc.data(),id:doc.id,}))
    console.log(filteredData)
    setServiceList(filteredData)
        }
  useEffect(()=>{

    getServiceList();
  },[])

  const lang =(localStorage.getItem(localStorage.key("i18nextLng")));

  const { t ,i18n} = useTranslation();
document.body.dir = i18n.dir();



  useEffect(()=>{
		Aos.init({duration:2000})
	
	  },[]);


  return (
    <div  className="servicef component__space" id="Services">
            <div className="heading">
            <Link to='/services' > <h1 className="pdetail">{t('Services')}</h1></Link>
               
             
            </div>

            <div  className="servcontainer">
            {
                 servicelist.map((elem) => {
             const { id, name, image, description } = elem;

                           return (
                            <div className="serrow">
                            <div className="col__3">
                            <div className="service__box pointer">
                               <div className="icon">
                               <img src={image} ></img>
                               </div>
                               <div className="service__meta">
                            
                                   {

lang==="fa" ?
<p className="p service__text p__color">{elem.kuDesc}</p>
   : lang==="ar"?
   <p className="p service__text p__color">{elem.arDesc }</p>
    :<p className="p service__text p__color">{elem.enDesc }</p>

}
                                   
                                  
                               </div>
                            </div>
                       </div>
                       </div>
        
        )
      })
                      }
           

   
    

</div>

</div>

  )
}
export default Service;