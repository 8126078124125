import React,{ useState, useEffect } from 'react'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css" 
import "slick-carousel/slick/slick-theme.css"
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import tEn from './locals/en/translation.json';
import tAr from './locals/ar/translation.json';
import tFa from './locals/fa/translation.json';

// firebase
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import { storage } from '../firebase';
//

i18n
  .use(initReactI18next) 
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: tEn
      },
      ar: {
        translation: tAr
      },
      fa: {
        translation: tFa
    },

    lng: "en", 
    fallbackLng: "en",
    interpolation: {
      escapeValue: false 
    }}});

export const Test = () => {
  const { t ,i18n} = useTranslation();
  document.body.dir = i18n.dir();
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);


 
  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    const imageListRef = ref(storage, "gallery/");
    listAll(imageListRef)
      .then((response) => Promise.all(response.items.map(getDownloadURL)))
      .then((urls) => setImageList(urls))
      .catch((error) => console.log("Error fetching image list:", error));
  }, []);

  useEffect(() => {

    setNav1(slider1);
    setNav2(slider2);

  });

  let settings={
    speed:500,
    slidesToShow:3,
    slidesToScroll:3,
    initialSlide:1,
    swipeToSlide: true,
    cssEase:"linear",
    swipeToSlide:true,
    centerMode:false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  
   }
 let settingss={
  speed:500,
  slidesToShow:4,
  slidesToScroll:3,
  initialSlide:1,
  swipeToSlide: true,
  arrows:false,
  cssEase:"linear",
//  centerMode: true,
  swipeToSlide:true,
  swipeToSlide: true,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }
  ]

 }
 
//

    return (
      
      <div className='galcontainergal'>
  <div className='toppContenttt'>
   <h1>{t('Gallery')}</h1>
        </div>
        <div className='mt-5 carousel'>
<Slider   {...settings}   infinite autoplay showArrows={true}  asNavFor={nav2}     ref={slider => (setSlider1(slider))}>
{/* {
    Mediaa.map((ele) => {
      const {image} = ele;
      return(
<div className='galcard-wrappergal'>
  <div className='galcardgal'>
    <div className='galcard-imagegal' key={ele.id}>
   

<img src={image} alt=""/>

    </div>

  </div>
</div>
)
}) } */}
{
  imageList.map((url)=>{
    return(
      <div className='galcard-wrappergal'>
        <div className='galcardgal'>
          <div className='galcard-imagegal' >
         
      
      <img src={url} alt=""/>
      
          </div>
      
        </div>
      </div>
      )
  })
 }



</Slider>
</div>
<div className='mt-5 carouselt'>
<Slider  {...settingss}
            asNavFor={nav1}
            ref={slider => (setSlider2(slider))}>
{/* {
    Mediaa.map((ele) => {
      const {image} = ele;
      return(
<div className='galcard-wrappergalt'>
  <div className='galcardgalt'>
    <div className='galcard-imagegalt' key={ele.id}>
   

<img src={image} alt=""/>

    </div>

  </div>
</div>)
}) } */}
{
  imageList.map((url)=>{
    return(
      <div className='galcard-wrappergalt'>
      <div className='galcardgalt'>
        <div className='galcard-imagegalt'>
         
      
      <img src={url} alt=""/>
      
          </div>
      
        </div>
      </div>
      )
  })
 }



</Slider>
</div>



</div>

    )
}

export default Test