import React from 'react'
import Banner from '../Banner';
import Projects from '../Projects';
import Service from '../Service';

const Home = () => {


  return (
  <>
  <div>
  <Banner/>

  <Service/>
 <Projects/>
  </div>
   </>
  )
}

export default Home