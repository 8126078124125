import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useState, useEffect } from "react";
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import { storage } from '../firebase';



export const Banner = () => {


    const [imageList, setImageList] = useState([]);

    useEffect(() => {
      const imageListRef = ref(storage, "carousel/");
      listAll(imageListRef)
        .then((response) => Promise.all(response.items.map(getDownloadURL)))
        .then((urls) => setImageList(urls))
        .catch((error) => console.log("Error fetching image list:", error));
    }, []);

  return (<>
  
    <section className="banner" id="home">
      
        <div id="carouselExampleSlidesOnly"   className="carousel slide" data-mdb-ride="carousel" >

   <div className="carousel-wrapper" >

<Carousel    panGestureHandlerProps={{ activeOffsetX: [-10, 10], }} showStatus={false} showThumbs={false}  infiniteLoop autoPlay preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={50} >
{
  imageList.map((url)=>{
    return<img src={url}/>
  })
 }

</Carousel>
</div>
</div>
    </section>
     </>
  )
 
}
export default  Banner;