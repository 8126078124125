import React, { useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
import DropdownL from './DropdownL';
import Aos from "aos";
import "aos/dist/aos.css";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import tEn from './locals/en/translation.json';
import tAr from './locals/ar/translation.json';
import tFa from './locals/fa/translation.json';
import log from  "../assets/img/falog.png";

i18n
  .use(initReactI18next) 
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: tEn
      },
      ar: {
        translation: tAr
      },
      fa: {
        translation: tFa
    },

    lng: "en", 
    fallbackLng: "en",
    interpolation: {
      escapeValue: false 
    }}});

   

function Header() {
  const { t ,i18n} = useTranslation();
  document.body.dir = i18n.dir();
  const [click, setClick] = useState(false);

  const [dropdownn, setDropdownn] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

 
  const onMouseEnterr = () => {
    if (window.innerWidth < 960) {
      setDropdownn(true);
    } else {
      setDropdownn(true);
    }
  };

  const onMouseLeavee = () => {
    if (window.innerWidth < 960) {
      setDropdownn(false);
    } else {
      setDropdownn(false);
    }
  };
	useEffect(()=>{
		Aos.init({duration:2000})
		
	  },[]);
  return (
    <>

      <nav   data-aos="slide-down" className='navbar'>
        <Link to='/' className='navbar-logo' onClick={closeMobileMenu}><img src={log}></img></Link>
        <div className='menu-icon' onClick={handleClick}>
          <i className={click ?'fas fa-times'  : 'fas fa-bars'} />
        </div>
        <ul  className={click ? 'nav-menu' :  'nav-menu activee'}>
          <li  className='nav-item'>
            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
             <span> {t('HOME')}</span>
            </Link>
   
         </li>
          <li  className='nav-item'>
            <Link
              to='/services'
              className='nav-links'
              onClick={closeMobileMenu}
            >
            <span>{t('SERVICES')}</span>  
            </Link>
          </li>
          <li  className='nav-item'>
            <Link
              to='/projects'
              className='nav-links'
              onClick={closeMobileMenu}
            >
             <span>{t('PROJECTS')}</span> 
            </Link>
          </li>
          <li  className='nav-item'>
            <Link
              to='/gallery'
              className='nav-links'
              onClick={closeMobileMenu}
            >
            <span> {t('GALLERY')}</span> 
            </Link>
          </li>
        
          <li  className='nav-item'>
            <Link
              to='/about'
              className='nav-links'
              onClick={closeMobileMenu}
            >
            <span>{t('ABOUT US')}</span>  
            </Link>
          </li>
          <li  className='nav-item'>
            <Link
              to='/contact'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              <span>  {t('CONTACT US')}</span>
             
            </Link>
          </li>
          <li
            className='nav-item'
            onMouseEnter={onMouseEnterr}
            onMouseLeave={onMouseLeavee}
          >
            <Link
             
              className='nav-links nav-linkss'
    
            >
              <div className='dropdown-link'>
            <img    src='https://upload.wikimedia.org/wikipedia/commons/f/f4/White_Globe_Icon.png'></img></div>
            </Link>
           
            {dropdownn && <DropdownL/>
            }
           
          </li>
         
        </ul>
    
      </nav>
 
    </>
  );
}

export default Header;
