import {React,useEffect,useState} from "react";
import {MdLocationOn} from "react-icons/md"
import {BsFillTelephoneFill} from "react-icons/bs"
import {AiFillMail} from "react-icons/ai"
import Aos from "aos";
import "aos/dist/aos.css";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import tEn from './locals/en/translation.json';
import tAr from './locals/ar/translation.json';
import tKu from './locals/fa/translation.json';
//firebase
import {db} from "../firebase"
import {addDoc,collection,getDocs} from "firebase/firestore"
//
i18n
  .use(initReactI18next) 
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: tEn
      },
      ar: {
        translation: tAr
      },
      fa: {
        translation: tKu
    },

    lng: "en", 
    fallbackLng: "en",
    interpolation: {
      escapeValue: false 
    }}});



export const Contact = () => {

  //firebase 
const [name,setName]=useState();
const [email,setEmail]=useState();
const [message,setMessage]=useState();

const userCollectionRef=collection(db,"contactdata");
//

//firebase
const handleSubmit=()=>{
addDoc(userCollectionRef,{
  name:name,
  email:email,
  message:message
}).then(()=>{
  if(!alert("Message Sent"))document.location = "/"
})
}
//
const lang =(localStorage.getItem(localStorage.key("i18nextLng")));
  const { t ,i18n} = useTranslation();
	document.body.dir = i18n.dir();
  
  useEffect(()=>{
    Aos.init({duration:2000})
    
    },[]);
   
    const [servicelist,setServiceList]=useState([]);
    const serviceCllectionRef=collection(db,"footer");
    const getServiceList =async()=>{
      //read data 
     
      const data=await getDocs(serviceCllectionRef);
      const filteredData=data.docs.map((doc)=>({...doc.data(),id:doc.id,}))
      console.log(filteredData)
      setServiceList(filteredData)
          }
    useEffect(()=>{
  
      getServiceList();
    },[])
  return (

    <>
   
    <section className="ccontactt" id="connect">
     <div className="ccontact-headingg">
      <h2 data-aos="fade">{t('Contact Us')}</h2>
     </div>
     <div className="ccontainerr">
      <div className="croww">
        <div className="ccolumnn">
          <div  data-aos="slide-up" className="ccontact-widgett">
          {
                 servicelist.map((elem) => {
                  const { id, name, image, description } = elem;

                  return (
                    <>
            <div className="ccontact-widgett-itemm">
              <div className="ciconn">
              <a href="#"><i ><MdLocationOn/></i></a>
              </div>
              <div className="ctextt">
                <h5>{t('Address')}</h5>
                {

lang==="fa" ?
<p>{elem.kuAddress}</p> 
   : lang==="ar"?
   <p>{elem.arAddress}</p> 
    : <p>{elem.enAddress}</p> 

}              </div>
            </div>





            <div className="ccontact-widgett-itemm">
              <div className="ciconn">
              <a href="#"><i ><BsFillTelephoneFill/></i></a>
              </div>
              <div className="ctextt">
                <h5>{t('Phone no.')}</h5>
                {

lang==="fa" ?
<p href={elem.enphoneNo1}>{elem.kuphoneNo1}</p> 
   : lang==="ar"?
   <p href={elem.enphoneNo1}>{elem.arphoneNo1}</p> 
    : <p href={elem.enphoneNo1}>{elem.enphoneNo1}</p> 

}  

<br/>
{

lang==="fa" ?
<p href="tel:+964 782 100 0090">{elem.kuphoneNo2}</p> 
   : lang==="ar"?
   <p href="tel:+964 782 100 0090">{elem.arphoneNo2}</p> 
    : <p href="tel:+964 782 100 0090">{elem.enphoneNo2}</p> 

} 
              </div>
            </div>

            <div className="ccontact-widgett-itemm">
              <div className="ciconn">
              <a href="#"><i ><AiFillMail/></i></a>
              </div>
              <div className="ctextt">
                <h5>{t('Mail')}</h5>
                
                <p>{elem.Email}</p>
              </div>
            </div>
            </>
      )})}
          </div>
        </div>

        <div  data-aos="fade-up" className="ccolumnn">
          <div className="ccontact-formm">
            <form className="cformm" action="#">
              <input type="text" placeholder={t('Name')} 
              onChange={(event)=>{
                setName(event.target.value)
              }}
              />
              <input type="email" placeholder={t('Email')}
               onChange={(event)=>{
                setEmail(event.target.value)
              }}/>
              <textarea placeholder={t('Comment')}
               onChange={(event)=>{
                setMessage(event.target.value)
              }}
              ></textarea>
              <button onClick={handleSubmit} type="submit" className="site-btn">{t('Send')}</button>
            </form>
          </div>
        </div>




      </div>

      <div  data-aos="fade" className="croww">
        <div className="cmap-columnn">
          <div className="ccontact-mapp">
          <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d25756.32958865672!2d43.97014565989864!3d36.2020384748569!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sIraq%2C%20Erbil%20-%20Dream!5e0!3m2!1sen!2siq!4v1674022964959!5m2!1sen!2siq" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
     </div>
               
    </section>
    </>
  )
}
export default Contact;