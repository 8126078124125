import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Carousel } from 'react-carousel-minimal';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {  collection, doc, getDoc } from 'firebase/firestore';
import{db} from "../firebase";
export const Media = () => {
  const [project, setProject] = useState({});
  const { id } = useParams();

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const projectRef = doc(collection(db, 'allprojects'), id);
        const projectDoc = await getDoc(projectRef);
        if (projectDoc.exists()) {
          setProject(projectDoc.data());
        } else {
          console.log('No such document!');
        }
      } catch (err) {
        console.log('Error getting document', err);
      }
    };
    fetchProject();
  }, [db, id]);

  const lang = localStorage.getItem(localStorage.key('i18nextLng'));
  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  };

  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  };

  const thumbNailsStyle = {
    border: 'none',
  };
 

  return (
    <div style={{ textAlign: 'center' }}>
      <div className='toppContentt'>
        {lang === 'fa' ? (
          <h3>{project.kuTitle}</h3>
        ) : lang === 'ar' ? (
          <h3>{project.arTitle}</h3>
        ) : (
          <h3>{project.enTitle}</h3>
        )}
        {lang === 'fa' ? (
          <p>{project.kuDesc}</p>
        ) : lang === 'ar' ? (
          <p>{project.arDesc}</p>
        ) : (
          <p>{project.enDesc}</p>
        )}
      </div>
   
      <div style={{ padding: '0 20px', marginTop: '2vh', marginBottom: '16vh' }}>
  {project.attachments && (
    <Carousel
      data={project.attachments}
      time={2000}
      width='850px'
      height='500px'
      captionStyle={captionStyle}
      radius='10px'
      slideNumber={true}
      slideNumberStyle={slideNumberStyle}
      captionPosition='bottom'
      automatic={true}
      pauseIconColor='white'
      pauseIconSize='40px'
      slideBackgroundColor='darkgrey'
      slideImageFit='cover'
      thumbnails={true}
      thumbNailsStyle={thumbNailsStyle}
      thumbnailWidth='80px'
      thumbnailHeight='80px'
      showArrows={false}
      style={{
        textAlign: 'center',
        maxWidth: '850px',
        maxHeight: '500px',
        margin: '40px auto',
        border: 'none',
      }}
    />
  )}
</div>
    </div>
  );
};

export default Media;


