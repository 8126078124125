import { useEffect,useState } from "react";
import bg1 from "../assets/img/hig4.jpg"
import bg2 from "../assets/img/hig7.jpg";
import Aos from "aos";
import "aos/dist/aos.css";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import tEn from './locals/en/translation.json';
import tAr from './locals/ar/translation.json';
import tKu from './locals/fa/translation.json';
import Vvv from "./Vvv";

import {getDocs,collection} from "firebase/firestore"
import{db} from "../firebase";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  list,
} from "firebase/storage";
import { storage } from '../firebase';


i18n
  .use(initReactI18next) 
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: tEn
      },
      ar: {
        translation: tAr
      },
      fa: {
        translation: tKu
    },

    lng: "en", 
    fallbackLng: "en",
    interpolation: {
      escapeValue: false 
    }}});

const About = () => {
  const [imageList,setImageList]=useState([]);
  const imageListRef=ref(storage,"about/")
 

  const [servicelist,setServiceList]=useState([]);
  const serviceCllectionRef=collection(db,"about");
  const getServiceList =async()=>{
    //read data 
   
    const data=await getDocs(serviceCllectionRef);
    const filteredData=data.docs.map((doc)=>({...doc.data(),id:doc.id,}))
    console.log(filteredData)
    setServiceList(filteredData)
        }
  useEffect(()=>{

    getServiceList();
  },[])


  const lang =(localStorage.getItem(localStorage.key("i18nextLng")));
	const { t ,i18n} = useTranslation();
	document.body.dir = i18n.dir();

	useEffect(()=>{
		Aos.init({duration:2000})
		
	  },[]);
    useEffect(()=>{
      listAll(imageListRef).then((response)=>{
        response.items.forEach((items)=>{
          getDownloadURL(items).then((url)=>{
      setImageList((prev)=>[...prev,url])
      
          })
        })
      })
        },[])
  return (
    <>
<div className="abbod">
	<div className="abouttt">
	<h1 data-aos="fade" >{t('Al Fakhama Architects')}</h1>
		<h2 data-aos="fade" >{t('Projects Of Luxury')}</h2>
<div className="aboutt">
	<div data-aos="slide-up" className="aleft">
		
{/* <p>{t('An international engineering consultant office in Architectural and Interior design services in iraq.')} </p>
	<p>{t('We specialize in full-service Architectural and interior design, including custom furniture design, lighting and decorative artwork designs procurement/installation. ')}</p>
	<p>{t('Our projects resume includes new homes, offices, restaurants, hotels , commercial buildings designs and branded artistic products.')}</p>
	<p>{t('Responsive to our clients vision, we are committed to design what expresses the interrelationships between architecture and place, traditional and modern concepts, space and form, color and materials, economy and integrity.')}</p>
	 */}

{
                 servicelist.map((elem) => {
                  const { id, name, image, description } = elem;

                  return (
                    <>
                  {

                    lang==="fa" ?
                    <p className="p service__text p__color">{elem.ku1 }</p>
                       : lang==="ar"?
                       <p className="p service__text p__color">{elem.ar1 }</p>
                        :<p className="p service__text p__color">{elem.en1}</p>
                    
                    }
                      {

lang==="fa" ?
<p className="p service__text p__color">{elem.ku2 }</p>
   : lang==="ar"?
   <p className="p service__text p__color">{elem.ar2 }</p>
    :<p className="p service__text p__color">{elem.en2}</p>

}

{

lang==="fa" ?
<p className="p service__text p__color">{elem.ku3 }</p>
   : lang==="ar"?
   <p className="p service__text p__color">{elem.ar3 }</p>
    :<p className="p service__text p__color">{elem.en3}</p>

}

{

lang==="fa" ?
<p className="p service__text p__color">{elem.ku4 }</p>
   : lang==="ar"?
   <p className="p service__text p__color">{elem.ar4 }</p>
    :<p className="p service__text p__color">{elem.en4}</p>

}
                    </>
                  )    
                 
                 })}
                            
	</div>
	<div  className="aright">
		<div className="img-acontainer">

<div data-aos="slide-up" className="img-stack top">

	<img className="aboutimage" src={bg1}></img>
</div>
<div data-aos="slide-up" className="img-stack bottom">

	<img className="aboutimage" src={bg2}></img>
</div>

{/* {
  imageList.map((url)=>{
    return<div ><img src={url}/></div>
  })
 } */}
		</div>
	</div>
</div>
		</div>
		<Vvv/>
    </div>
  </>
	
  );
}

export default About;
