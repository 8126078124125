import { useEffect, useState } from "react";
import { Link, useLocation} from 'react-router-dom';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import tEn from './locals/en/translation.json';
import tAr from './locals/ar/translation.json';
import tKu from './locals/fa/translation.json';
import "aos/dist/aos.css";
import{db} from "../firebase";
import {getDocs,collection} from "firebase/firestore"
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: tEn
      },
      ar: {
        translation: tAr
      },
      fa: {
        translation: tKu
      },

      lng: "en",
      fallbackLng: "en",
      interpolation: {
        escapeValue: false
      }
    }
  });

export const Services = () => {
  const lang =(localStorage.getItem(localStorage.key("i18nextLng")));

  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();
  const [data, setData] = useState([]);
  const [collectionn, setCollection] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categories=collection(db,"allprojects");
        const projects=collection(db,"projects");

        const data=await getDocs(categories);
        const data2=await getDocs(projects);
        const categoriesData=data.docs.map((doc)=>({...doc.data(),id:doc.id,}))
        const projectsData=data2.docs.map((doc)=>({...doc.data(),id:doc.id,}))

      
        setCollection([...new Set(projectsData)]);
        if (location.state) {
          const filterData = categoriesData.filter(item => item.categoryId === location.state);
          setData(filterData);
        } else {
          setData(categoriesData);
        }
      } 
      catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [location]);

  const gallery_filter = async (itemData) => {
    const categories=collection(db,"allprojects");
    const data= await getDocs(categories);
    const categoriesData=data.docs.map((doc)=>({...doc.data(),id:doc.id,}))
   
    if (itemData.id == 0) {
      setData(categoriesData);
    } else {
      const filterData = categoriesData.filter((item) => item.categoryId === itemData.id);
      setData(filterData);
    }
  };


  return (<>
    <div className="pmbod ppbody">

      <div className="galleryWrapper">

        <div className="filterItem">
          <ul>





            {
              
              collectionn.map((item) => {
       return(
              <li><button onClick={() => { gallery_filter(item) }}>{lang==="fa" ?
            <>  {item.kuTitle}</>
              : lang==="ar"?
              <> {item.arTitle}</>
              : <> {item.enTitle}</>
            } </button></li>
      
       )   
            }  )

            }
          </ul>
        </div>
        <div className="pcontainer">
          <div className="prow">

          {
                  data.map((item) => {
                    return (
                  <div key={item.id} >
                <div className="pimage">
                  <img className="img-fluid" src={item.image} alt="" />
                  <div className="pdetails">

                  {

lang==="fa" ?
     <h2 >{item.kuTitle}</h2>
   : lang==="ar"?
    <h2 >{item.arTitle}</h2>
    :  <h2 >{item.enTitle}</h2>
}
   

{

lang==="fa" ?
<p>{t(item.kuDesc)}</p>
   : lang==="ar"?
   <p>{t(item.arDesc)}</p>
    :    <p>{t(item.enDesc)}</p>
}
 


   
                
                    <div className="pmore"><Link to={`/project/${item.id}`} >

                    <div className="pread-more"  >
            
            {

lang==="fa" ?
 <>وێنەکان ببینە</>
: lang==="ar"?
   <>مشاهدة الصور</>
    :  <>View Images</>
}
   
             
              </div>
                      
                      </Link></div>
                  </div>
                </div>
              </div>
              )
            })
                            }
          </div>
        </div>

      </div>
    </div>
  </>
  )
}

export default Services;